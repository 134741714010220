<template>
  <div class="home">
    <!-- <el-carousel height="500px">
      <el-carousel-item v-for="item in 4" :key="item">
        <img alt="Vue logo" src="../assets/logo.png" height="500px" />
      </el-carousel-item>
    </el-carousel> -->
    <div class="buttons">
      <div
        style="cursor: pointer"
        @click="jumpToLogin('https://cases.boaihealth.cn/#/login')"
      >
        <div class="item">
          <img src="@/assets/images/ychz.png" />
        </div>
        <div style="font-size: 10px">电子病历</div>
      </div>
      <div
        style="cursor: pointer"
        @click="
          jumpTo(
            'https://www.bsc1.cn/bscsso/login?service=https://www.bsc1.cn/BSCUltrasound/ultrasound/index'
          )
        "
      >
        <div class="item">
          <img src="@/assets/images/yccs.png" />
        </div>
        <div style="font-size: 10px">远程超声</div>
      </div>
      <div
        style="cursor: pointer"
        @click="
          jumpTo(
            'https://www.bsc1.cn/bscsso/login?service=https://www.bsc1.cn/DicomCloud/cloud/index'
          )
        "
      >
        <div class="item">
          <img src="@/assets/images/ycyx.png" />
        </div>
        <div style="font-size: 10px">远程影像</div>
      </div>
      <div
        style="cursor: pointer"
        @click="
          jumpTo(
            'https://www.bsc1.cn/bscsso/login?service=https://www.bsc1.cn/BSCTelmed/teleconsultation/toIndex'
          )
        "
      >
        <div class="item">
          <img src="@/assets/images/ycyxjy.png" />
        </div>
        <div style="font-size: 10px">远程会诊</div>
      </div>
      <div
        style="cursor: pointer"
        @click="
          jumpTo(
            'https://redcross.macrocura.com/#/passport/login'
          )
        "
      >
        <div class="item">
          <img src="@/assets/images/zhongyi.png" />
        </div>
        <div style="font-size: 10px">中医辅诊</div>
      </div>
      <!-- <div
        style="cursor: pointer"
        @click="
          jumpTo('https://www.bsc1.cn/BSCEdu/education/video/front/index')
        "
      >
        <div class="item">
          <img src="@/assets/icons/2.png" />
        </div>
        <div style="font-size: 10px">学习培训</div>
      </div> -->
      <!-- <div style="cursor: pointer" @click="$router.push('/apps')">
        <div class="item">
          <img src="@/assets/icons/3.png" />
        </div>
        <div style="font-size: 10px">更多应用</div>
      </div> -->
    </div>
    <div class="section">
      <div class="title">
        新闻动态
        <div class="more" @click="$router.push('/news')">查看更多 ></div>
      </div>
      <div
        style="position: relative"
        @mouseenter="stopRolling"
        @mouseleave="startRolling"
      >
        <!-- <transition-group name="list" tag="ul" class="infinite-list" v-infinite-scroll="load" style="overflow: auto"> -->
        <ul
          class="infinite-list"
          v-infinite-scroll="load"
          style="overflow: auto"
        >
          <li
            v-for="(item, index) in newsList"
            class="infinite-list-item"
            :key="item.id"
            @mouseenter="picChange(item.cover, item, index)"
            @click="goDetail(item, index)"
          >
            <div
              style="
                text-align: center;
                border-right: 1px solid #d3d3d3;
                padding: 0 10px;
                color: #aaaaaa;
              "
            >
              <div style="font-size: 28px">
                {{ item.issueTime.split('-')[2].split(' ')[0] }}
              </div>
              <div style="font-size: 12px; white-space: nowrap">
                {{
                  `${item.issueTime.split('-')[0]}-${
                    item.issueTime.split('-')[1]
                  }`
                }}
              </div>
            </div>
            <div style="padding: 0 10px; width: calc(100% - 130px)">
              <div
                style="
                  font-weight: 600;
                  font-size: 18px;
                  max-width: 100%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                "
              >
                {{ item.title }}
              </div>
              <div
                style="
                  font-size: 14px;
                  max-width: 100%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  color: #aaaaaa;
                "
              >
                {{ item.introduction }}
              </div>
            </div>
            <div style="text-align: center; padding: 0 0 0 10px">
              <div style="font-size: 20px">
                <img
                  src="@/assets/icons/go.png"
                  style="width: 30px; height: 30px"
                />
              </div>
            </div>
          </li>
        </ul>
        <!-- </transition-group> -->
        <Transition name="fade">
          <img
            style="
              display: inline-block;
              width: calc(40% - 20px);
              height: 300px;
              position: absolute;
              cursor: pointer;
            "
            :style="{ objectFit: `contain` }"
            :src="imgSrc"
            :key="imgSrc"
            @click="goDetail(curItem, curIndex)"
          />
        </Transition>
      </div>
    </div>
    <div class="section" style="margin-bottom: 60px;">
      <div class="title">
        项目介绍
        <div class="more" @click="$router.push('/cases')">查看更多 ></div>
      </div>
      <div
        v-html="videoUrl"
        v-if="videoUrl"
        class="player"
        style="width: 100%; height: 400px"
      ></div>
      <video
        v-else
        :src="`${publicPath}ch.mp4`"
        controls
        style="width: 100%; margin-top: 20px"
      />
      <!-- <div class="cases" style="position: relative; height: 500px">
        <div
          class="left"
          @click="
            $router.push({ path: '/detail/' + 1, query: { type: 'cases' } })
          "
        >
          <img src="@/assets/news1/3.jpg" />
        </div>
        <div class="right">
          <div
            class="up"
            @click="
              $router.push({ path: '/detail/' + 3, query: { type: 'cases' } })
            "
          >
            <img src="@/assets/news3/1.jpg" />
            <div
              style="
                position: absolute;
                bottom: 0;
                font-size: 16px;
                color: #333333;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              <el-tag type="success" style="height: 20px; line-height: 20px"
                >99公益日</el-tag
              >
              第一天，让公益走向大众，「丰基金」在行动！
            </div>
          </div>
          <div
            class="down"
            @click="
              $router.push({ path: '/detail/' + 4, query: { type: 'cases' } })
            "
          >
            <img src="@/assets/news4/2.jpg" />
            <div
              style="
                position: absolute;
                bottom: 0;
                font-size: 16px;
                color: #333333;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              <el-tag type="success" style="height: 20px; line-height: 20px"
                >99公益日</el-tag
              >
              男女老少皆宜，花样募捐走起！
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="section">
      <div class="title">
        公益合作
        <div class="more" @click="$router.push('/collab')">查看更多 ></div>
      </div>
      <div style="position: relative" v-if="!collabList || collabList.length == 0">
        <el-carousel height="100px" indicator-position="outside">
          <el-carousel-item v-for="item in 4" :key="item">
            <div class="collabPage">
              <div class="collabItem" style="border: 1px solid #eeeeee">
                <img src="@/assets/collab/7.png" style="object-fit: contain" />
              </div>
              <div class="collabItem" style="border: 1px solid #eeeeee">
                <img src="@/assets/collab/5.png" style="object-fit: contain" />
              </div>
              <div class="collabItem">
                <img src="@/assets/collab/7.jpg" />
              </div>
              <div class="collabItem" style="border: 1px solid #eeeeee">
                <img src="@/assets/collab/6.png" style="object-fit: contain" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="position: relative" v-else>
        <el-carousel height="100px" indicator-position="outside">
          <el-carousel-item v-for="item in Math.ceil(collabList.length / 4)" :key="item">
            <div class="collabPage">
              <div class="collabItem" style="border: 1px solid #eeeeee" v-for="collab in collabList.slice((item - 1) * 4, item * 4)" :key="collab.id">
                <img :src="collab.img" style="object-fit: contain" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
    <!-- <div
      class="section collab"
      style="
        height: 300px;
        margin-bottom: 60px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        cursor: pointer;
      "
      @click="$router.push('/collab')"
    >
      <div
        style="
          float: right;
          height: calc(100% - 80px);
          padding-top: 80px;
          margin-right: 20px;
        "
      >
        <div style="font-size: 32px; font-weight: 600">科教研合作登记表</div>
        <div style="font-size: 18px; margin-top: 20px">
          与各方携手共进、并肩同行、传递温暖、守护讲课
        </div>
        <div
          style="
            border-radius: 3px;
            border: 1px solid red;
            padding: 5px;
            width: fit-content;
            font-size: 18px;
            color: #e60113;
            margin-top: 20px;
          "
        >
          了解详情 <i class="el-icon-right" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { fetchData, fetchVideo, fetchCollab } from '@/api/home'
export default {
  name: 'HomeView',
  data() {
    return {
      count: 15,
      imgSrc: require(`@/assets/login-logo.png`),
      curItem: null,
      curPic: 0,
      curIndex: 0,
      publicPath: process.env.BASE_URL,
      videoUrl: null,
      collabList: null,
      newsData: [
        {
          title: '博爱健康e站培训通知（十一月）',
          content: '培训通知',
          date: '2022-11-01',
          type: '培训通知',
          pic: 111
        },
        {
          title: '博爱健康e站培训通知（十月）',
          content: '培训通知',
          date: '2022-10-01',
          type: '培训通知',
          pic: 110
        },
        {
          title: '99公益日｜第一天，让公益走向大众，「丰基金」在行动！',
          content:
            '直接捐，一起捐，答题捐，地推捐，小红花捐，志愿者们在线上踊跃转发募捐，可见前两次我们总结的捐款攻略，大家都拿捏的非常准确！',
          date: '2022-09-08',
          type: '公益合作',
          pic: 3
        },
        {
          title: '博爱健康e站培训通知（九月）',
          content: '培训通知',
          date: '2022-09-01',
          type: '培训通知',
          pic: 109
        },
        {
          title: '博爱健康e站培训通知（八月）',
          content: '培训通知',
          date: '2022-08-01',
          type: '培训通知',
          pic: 108
        },
        {
          title: '中国红十字基金会“博爱基层公卫援建计划”医务人员培训在重庆举行',
          content:
            '7月20日，中国红十字基金会“博爱基层公卫援建计划”医务人员培训活动在重庆市巫山县举行。',
          date: '2022-07-23',
          type: '公益合作',
          pic: 1
        },
        {
          title: '博爱健康e站培训通知（七月）',
          content: '培训通知',
          date: '2022-07-01',
          type: '培训通知',
          pic: 107
        },
        {
          title: '博爱健康e站培训通知（六月）',
          content: '培训通知',
          date: '2022-06-01',
          type: '培训通知',
          pic: 106
        },
        {
          title: '博爱健康e站培训通知（五月）',
          content: '培训通知',
          date: '2022-05-01',
          type: '培训通知',
          pic: 105
        },
        {
          title: '博爱健康e站培训通知（四月）',
          content: '培训通知',
          date: '2022-04-01',
          type: '培训通知',
          pic: 104
        },
        {
          title: '博爱健康e站培训通知（三月）',
          content: '培训通知',
          date: '2022-03-01',
          type: '培训通知',
          pic: 103
        },
        {
          title: '中国红基会17所红十字博爱健康e站在重庆巫山投入使用',
          content:
            '近日，由中国红十字基金会携手三峡集团公益基金会援建的17所“红十字博爱健康e站”在重庆巫山县官渡镇、巫峡镇、福田镇等地落成并陆续投入使用，为乡村群众提供高质量医疗服务。',
          date: '2022-02-21',
          type: '公益合作',
          pic: 7
        },
        {
          title: '博爱健康e站培训通知（二月）',
          content: '培训通知',
          date: '2022-02-01',
          type: '培训通知',
          pic: 102
        },
        {
          title: '博爱健康e站培训通知（一月）',
          content: '培训通知',
          date: '2022-01-01',
          type: '培训通知',
          pic: 101
        },
        {
          title: '贵州毕节“红十字博爱健康e站”首批试点交付使用',
          content:
            '12月17日，中国红十字基金会副理事长刘选国，贵州省红十字会党组成员、副会长何淑平，上海大丰公益基金会秘书长朱天玲，南华和平医院管理有限公司董事长陈勇等一行至毕节高新区（金海湖新区）视察博爱基层公卫援建计划“红十字博爱健康e站”首批试点使用情况，毕节金海湖新区领导卢文蔚、毕节市红十字会党组成员马永义，李洪龙，陪同参加视察。',
          date: '2021-12-18',
          type: '公益合作',
          pic: 5
        },
        {
          title: '博爱基层公卫援建计划进入实施阶段',
          content:
            '中国红十字基金会“博爱基层公卫援建计划”正式进入实施阶段，持续探索增进社会福祉，助力共同富裕！',
          date: '2021-09-14',
          type: '公益合作',
          pic: 6
        }
      ],
      newsList: [],
      rollingIndex: 0,
      rolling: true,
      newsTimer: null
    }
  },
  mounted() {
    fetchVideo().then((res) => {
      this.videoUrl = res.data
    })
    fetchCollab().then((res) => {
      this.collabList = res.data
    })
    fetchData().then((res) => {
      this.newsList = res.data.boAiJournalismPojoList
      this.curItem = this.newsList[0]
      this.imgSrc = this.curItem.cover
      this.newsTimer = setInterval(() => {
        if (!this.rolling) {
          return true
        } else {
          let newData = this.newsList.splice(1)
          console.log(newData, 'newData', this.newsList)
          newData.push(...this.newsList)
          this.newsList = newData
          console.log(this.newsList, 'newData')
          this.picChange(this.newsList[0].cover, this.newsList[0], 0)
          // if (this.rollingIndex == this.oldData.length) this.rollingIndex = 0
          // const temp = this.oldData[this.rollingIndex]
          // this.picChange(temp.pic, temp, this.rollingIndex)
          // this.rollingIndex += 1;
        }
      }, 5000)
    })
    document.getElementsByClassName('el-header')[0].scrollIntoView()
    this.curPic = 110
  },
  beforeDestroy() {
    clearInterval(this.newsTimer)
  },
  methods: {
    stopRolling() {
      this.rolling = false
    },
    startRolling() {
      this.rolling = true
    },
    jumpTo(url) {
      window.open(url)
    },
    jumpToLogin(url) {
      if (this.$store.state.login) {
        window.open(
          url +
            `?username=${
              JSON.parse(localStorage.getItem('user')).username
            }&password=${JSON.parse(localStorage.getItem('user')).password}`
        )
      } else {
        window.open(url)
      }
    },
    picChange(index, item, curIndex) {
      console.log(index)
      this.curItem = item
      this.curIndex = curIndex
      this.curPic = index
      this.imgSrc = index
    },
    load() {
      console.log('load')
    },
    goDetail(item, index) {
      console.log(index)
      this.$router.push({
        path: '/detail/' + item.id,
        query: { type: 'news' }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: 2s;
}

.player ::v-deep iframe {
  width: 100%;
  height: 100%;
}

.home {
  padding-top: 60px;
  background: white;
}
.collab {
  background-size: cover;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 20%,
      white 70%
    ),
    url('../assets/images/banner_leftbg.png');
}
.cases {
  .left {
    width: calc(70% - 20px);
    margin-right: 20px;
    display: inline-block;
    height: 100%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .right {
    display: inline-block;
    height: 100%;
    width: 30%;

    .up,
    .down {
      height: calc(50% - 10px);
      position: relative;
      cursor: pointer;
      img {
        position: absolute;
        width: 100%;
        height: calc(100% - 30px);
        object-fit: cover;
      }
    }
    .up {
      margin-bottom: 20px;
    }
  }
}
.collabPage {
  width: 100%;
  height: 100px;
  .collabItem {
    background: white;
    width: calc(25% - 22px);
    height: 80px;
    margin: 10px;
    display: inline-block;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    img {
      width: 100%;
      object-fit: cover;
      height: 80px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.infinite-list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.infinite-list {
  display: inline-block;
  height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 60%;
  margin-right: 20px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  .infinite-list-item {
    display: flex;
    align-items: center;
    height: 80px;
    background: #ffffff;
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
  }
  .infinite-list-item:nth-child(1) {
    border-top: 1px solid #d3d3d3;
  }
}

.section {
  margin-top: 60px;
  text-align: left;
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .more {
    font-size: 12px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
  .item {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
}
</style>
