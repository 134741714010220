import request from '@/utils/request'

//无token
export function fetchData() {
  return request({
    url: '/api/v1/routine/findHomePage',
    method: 'get'
  })
}

export function fetchBanners() {
  return request({
    url: '/api/v1/user/findBanner',
    method: 'get'
  })
}

export function fetchVideo() {
  return request({
    url: '/api/v1/video',
    method: 'get'
  })
}

export function fetchCollab() {
  return request({
    url: '/api/v1/cooperation/getImg',
    method: 'get'
  })
}