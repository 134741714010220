import axios from 'axios'
import { MessageBox, Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (localStorage.getItem('token') && localStorage.getItem('token').length > 0) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI0Y2M4MjA0ZjUxYjc0N2M3OGI4OTJlZjFhODE4ZjMwZCIsInN1YiI6IjE1MzQ0NjEwODIxMzk0ODQxNjIiLCJpc3MiOiJzZyIsImlhdCI6MTY1NTQzNTg1MywiZXhwIjoxNjU2MDQwNjUzfQ.T7w_wkxSmNHNO5Yoc9eWLwc4L5aPuZrmGDs6DJNKwvc'
      config.headers['Authorization'] = localStorage.getItem('token')
      config.headers['token'] = localStorage.getItem('token')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (res.code === 10230) {
      console.log(res.code)
      MessageBox.confirm('您已下线，是否重新登录', '确认登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        
          location.reload()
        
      })
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 200 || res.code === 0) {
      return res
    }
    if (res.code !== 20000) {
      Message({
        message: res.errorMessage || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          
            location.reload()
          
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
